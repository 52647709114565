import Vue from 'vue'
import VueRouter from 'vue-router'
// import component from "vuetify/src/util/component";
//提示：都以懒加载的形式引入
const Home = () => import('../views/home/Home')
const Login = () => import('../views/home/Login')
const SignUp = () => import('../views/home/SignUp')
const HouseBasicInfo = () => import('../views/house_management/HouseBasicInfo')
const HouseSafeInfo = () => import('../views/house_management/HouseSafeInfo')  // 房屋信息管理
const SafeInfo = () => import('../views/house_management/SafeInfo')   //房屋安全信息
const HousingDisplay = () => import('../views/housing_display/HousingDisplay')  // 房屋展示
const PersonalCenter = () => import('../views/personal/Personal')  // 个人中心
const SafetyManagement = () => import('../views/safety_management/SafetyManagement')  // 安全指标
const UserManage = () => import('../views/userManage/UserManage') // 用户管理


Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'home'
  },
  {
    path:'/home',
    component:Home,
    redirect:'PersonalCenter',
    children:[
      {
        path:'/HouseBasicInfo',
        component:HouseBasicInfo
      },
      {
        path:'/HouseSafeInfo',
        component:HouseSafeInfo
      },
      {
        path:'/SafeInfo',
        component:SafeInfo
      },
      {
        path:'/HousingDisplay',
        component:HousingDisplay
      },
      {
        path:'/PersonalCenter',
        component:PersonalCenter
      },
      {
        path:'/SafetyManagement',
        component:SafetyManagement
      },
      {
        path:'/UserManage',
        component:UserManage
      },
    ]
  },
  {
    path:'/login',
    component:Login
  },
  {
    path: '/signup',
    component:SignUp
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if(to.path === '/login') return next();
  //获取token
  const tokenStr = window.localStorage.getItem('token');
  if(!tokenStr) return next('/login');
  next();
})
export default router
